<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ data.name ? data.name : "Новый" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <v-row>
        <v-col cols="12">
          <a-form-model v-model="data" :model="model" :errors="errors" @validate="validate($event)" :config="{ dense: true, readonly }" />
          <v-textarea
            v-model="data.crypt.content"
            label="Содержимое"
            outlined
            dense
            :readonly="cryptError"
            :error-messages="cryptError ? 'Ошибка расшифровки, попробуйте другой ключ' : ''"
          ></v-textarea>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <a-btn-crypt-code @select-code="onSelectCode($event)" type="list" badge />
        <v-spacer></v-spacer>
        <a-btn-save v-if="!readonly" :disabled="disabled" @click="submit()"></a-btn-save>
        <a-btn-delete v-if="id && !readonly" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, genModel } from "@/components/mixings";
import CryptoJS from "crypto-js";

export default {
  mixins: [getForm, submitForm, removeEl, genModel],
  props: {
    value: Boolean,
    id: Number,
    object_id: Number,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      key: null,
      m: this.$store.getters["config/get"].models.crypt,
      removeDialogShow: false,
      cryptError: false,
    };
  },
  computed: {
    disabled() {
      return false;
      return !this.key;
    },
    readonly() {
      if (!this.id) return false;
      return this.data.createdby_id !== this.$root.profile.id;
    },
    model() {
      let model = this.m.form;
      model.forEach((m) => {
        if (m.name == "access") m.hidden = this.data.createdby_id !== this.$root.profile.id && this.id;
      });
      return model;
    },
    api() {
      return this.m.api;
    },
    qrCodeList: {
      get() {
        return this.$root.qrCodeList;
      },
      set(v) {},
    },
  },
  created() {
    this.updateData(this.id);
    if (this.qrCodeList.length == 1) this.key = this.qrCodeList[0].code;
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  methods: {
    onSelectCode(e) {
      this.key = e.code;
      if (this.cryptError) this.updateData(this.id);
      console.log(e);
    },
    async fetchData(id) {
      if (id) {
        this.loading = true;
        let response;
        try {
          response = await this.$axios.get(this.api + "/" + id);
          let data = response.data.data;
          console.log(data);
          if (data.data) {
            let strData = data.data;
            try {
              if (this.key) {
                let str = CryptoJS.AES.decrypt(strData, this.key).toString(CryptoJS.enc.Utf8);
                strData = str;
              }

              data.crypt = JSON.parse(strData);
              this.cryptError = false;
            } catch (error) {
              console.log(error);
              this.cryptError = true;
            }
          }
          for (const name in data) {
            if (name == "crypt" && this.data?.crypt && data[name]) {
              for (const nameData in data.crypt) {
                this.$set(this.data.crypt, nameData, data.crypt[nameData]);
                this.$set(this.errors, nameData, null);
              }
            } else {
              this.$set(this.data, name, data[name]);
              this.$set(this.errors, name, null);
            }
          }
        } catch (error) {}
        this.loading = false;

        this.afterFetchData(response);
      } else this.afterFetchData();
    },
    async submit(silent = false) {
      await this.beforeSave();
      if (this.saving) return;
      this.saving = true;
      let data = Object.assign({}, this.data);
      delete data.crypt;

      if (!(!this.cryptError && this.key)) {
        delete data.data;
      } else {
        let strData = CryptoJS.AES.encrypt(JSON.stringify(this.data.crypt), this.key).toString();
        data.data = strData;
      }
      console.log("save", data);
      if (await this.validateAll(this.data, silent)) {
        await this.post(data, this.api);
        this.saving = false;
        return true;
      }
      //console.log("save2");
      this.saving = false;
      return false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>
